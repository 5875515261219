<template>
  <v-card>
    <v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Available Files for Batch No. {{batchDetails.batch_number}}
	<v-spacer />
	<v-btn icon dark @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
    </v-btn>
    </v-card-title>
	<br/>
    <v-card-text v-if="batchDetails">
			<v-data-table
				:headers="headers"
				:items="trxdisbDetailed"
				:loading="false"
				class="elevation-1"
			>
				<template v-slot:items="props">
					<td v-if="trxdisbDetailed.length > 0">{{ props.item.filename }}</td>
				</template>
				<template v-slot:[`item.file`]="{ item }">
					<v-icon class="text-right mr-2" color="primary" medium @click="viewItem(item.path)"
						>mdi-eye-circle</v-icon
					>
				</template>
			</v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		batchDetails: Array,
		attachmentDialog: Boolean
	},
	computed: {
		...mapGetters({
			trxdisbDetailed: 'trxdisb/trxdisbDetailed',
		})
	},
	mounted(){
	},
	data () {
		return {
			headers: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'filename'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			]
		};
	},
	methods: {
		closeDialog() {
			this.$store.dispatch('trxdisb/setAttachmentDialog', false);
		},
		viewItem(item) {
			let url = process.env.VUE_APP_STORAGE_URL + '/' + this.batchDetails.batch_number + '/' + item;
			window.open(url, '_blank'); 
		}
	}
};
</script>
<style scoped>
.create-disb-title {
	color: #FFFFFF;
}

.card-action-container {
	display: flex;
	flex-direction: row-reverse;
}

.v-card .v-divider.create-disb-divider {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
</style>