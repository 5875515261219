<template>
  <v-card>
	<v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Collection No. {{collectionDetails[0].collection_number}} 
      <v-spacer/>
      <v-btn icon dark @click="closeDialog(collectionDetails[0].collection_number)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
	<v-card-text>
    <br>
		<v-layout v-if="collectionDetails[0].remarks && collectionDetails[0].accounting_status === 10">
			<v-flex xs12 sm12 md12>
				<v-alert type="error" outlined>
					Void Collection : Reason - {{collectionDetails[0].remarks}}
				</v-alert>
			</v-flex>
		</v-layout>
      <v-layout>
        <v-flex xs12 sm12 md4>
			<br>
			<h3>Collection No: {{collectionDetails[0].collection_number}}</h3>
			<h3>Collection Date: {{collectionDetails[0].collection_date}}</h3>
			<h3>Collected Amount: {{collectionDetails[0].amount}}</h3>
			<h3>Invoice No: {{collectionDetails[0].billing_id}}</h3>
			<h3>Customer: {{getValue(payees, "id", collectionDetails[0].payee_id, "payee_desc")}}</h3>
			<h3>{{collectionDetails[0].receipt_type === '1' ? "Official Receipt" : "Acknowledgement Receipt"}} : {{ collectionDetails[0].receipt_no }}</h3>
		</v-flex><v-spacer/>
        <v-flex xs12 sm12 md6>
			<h3>Attached Files:</h3>
			<v-layout column style="height: 100px">
				<v-flex style="overflow: auto" class="elevation-3">
					<v-data-table
						:headers="attachHeaders"
						:items="collectionDetailed"
						:loading="false"
						class="elevation-1"
						:hide-default-footer="true"
						:hide-default-header="true"
						style="margin:0"
						:items-per-page="1000"
					>
						<template v-slot:items="props">
						<td>{{ props.item.title }}</td>					
						</template>
						<template v-slot:[`item.file`]="{ item }">
						<v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
							>mdi-eye-circle</v-icon
						>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
	<br><h2>Collection Entries</h2>
		<v-container class="doc-items-container" grid-list-md >
			<v-simple-table dense>
					<template v-slot:default>
                    <thead>
                      <tr class="text-center">
                        <th>#</th>
                        <th>Account Code</th>
						<th>Department</th>
						<th>Branch</th>
						<th>Section</th>
                        <th style="text-align:center">Debit Amount</th>
                        <th style="text-align:center">Credit Amount</th>
                      </tr>
                    </thead>
						<tbody>
                      <tr
                        v-for="(detail, detail_key) in collectionDetails[0].details"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                           {{ getValue(coaData, "acct_code", detail.account_code, "acct_desc") }}
                        </td>
                        <td>
                           {{ getValue(dataDepts, 'id', detail.department_id, "dept_code") }}
                        </td>
                        <td>
							{{ getValue(branches, 'id', detail.branch_id, "brnc_code") }}
                        </td>
                        <td>
                           {{ getValue(dataSections,'id',detail.section_id, "section_code" )}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.debit_amount)}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.credit_amount)}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" class="text-right">
                          <b>Total</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(getTotalAmount(collectionDetails[0], 'debit_amount'))}}</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(getTotalAmount(collectionDetails[0], 'credit_amount'))}}</b>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
		</v-container>
	</v-card-text>
	<v-card-actions v-if="collectionDetails[0].accounting_status===3 && parseFloat(currUser.max_amount) > parseFloat(level1.min_amount)"><!-- && parseFloat(currUser.max_amount) < parseFloat(level1.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveCollection = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectCollection = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="collectionDetails[0].accounting_status===4 && parseFloat(currUser.max_amount) > parseFloat(level2.min_amount)"><!-- && parseFloat(currUser.max_amount) < parseFloat(level2.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveCollection = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectCollection = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="collectionDetails[0].accounting_status===5 && parseFloat(currUser.max_amount) > parseFloat(level2.max_amount)">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveCollection = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectCollection = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-dialog
        v-model="approveCollection"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Approve Collection
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to Approve this Collection with Invoice No. {{collectionDetails[0].collection_number}} ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="approve(collectionDetails[0].collection_number, collectionDetails[0].accounting_status, collectionDetails[0].amount)"
            >
              Approve
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveCollection = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectCollection"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reject Collection
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to reject this Collection with Invoice No. {{collectionDetails[0].collection_number}} ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reject(collectionDetails[0].collection_number)"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectCollection = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';
import { getMonth, getYear, parseISO } from 'date-fns';
export default {
	props: {
		batchDetails: Object,
		documentDetails: Object
	},
	data() {
		return {
			level1: {},
			level2: {},
			level3: {},
			upload_files: '',
			upload_file: [],
			image_title: [],
			delete_file: [],
			seenAttachedFile:[],
			dataBranch: [],
			dataAtc: [],
			// dataPayees: [],
			dataDepts: [],
			dataSections: [],
			dataDollarRate: [],
			nextPageDollarRate: '',
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			nextPageSections: '',
			nextPageBranch: '',
			nextPageAtc: '',
			// nextPagePayees: '',
			nextPageDepts: '',
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'filename'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
			panel: 0,
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			error_message: 'Please view attached file before approving.',
			snackbar: false,
			approveCollection: false,
			rejectCollection: false,
			rejectCollectionReason: false,
			rejectRemarks: '',
			partner_bank_files: [],
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payor is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			dataCoas: [],
			nextPageCoas: '',
		};
	},
	computed: {
		...mapGetters({
			services: 'services/services',
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payees: 'payefile/payefiles',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			headers: 'trxcollection/headers',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			viewDialog: 'trxcollection/viewDialog',
			regulartrx: 'regularTxns/regulartxns',
			preparer: 'trxcollection/preparer',
			collectionDetails: 'trxcollection/collectionDetails',
			collectionDetailed: 'trxcollection/collectionDetailed',
			dollarrates: 'dollarrate/dollarrates',
			currencies: 'currency/currencies',
			approvinglevel: 'approvinglevel/approvinglevels',
			coaData: 'coa/coaData',
			coas: 'coa/coaSubs',
		})
	},
	watch: {
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		batchDetails: {
			handler(val) {
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		documentDetails: {
			handler(val) {
				this.$emit('update:documentDetails', val);
			},
			deep: true
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
		dollarrates: {
			handler() {
				this.getDataDollarRate();
			}
		},
		regulartrx: {
			handler() {
				this.getDataRegularTrx();
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
	},
	mounted() {
		this.$store.dispatch('services/getServices');
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('alphataxcode/getAlphataxcodes');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
		this.$store.dispatch('dollarrate/getDollarrates');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('coa/getCoaDropdown');
	},
	methods: {
		viewItem(item) {
			const details = this.collectionDetailed.filter(detail => {
				if(detail.path === item){
					detail.seen = true;
				}
				return detail;
			});
			this.$store.commit('trxbilling/COLLECTION_DETAIL', details);

			//let url = process.env.VUE_APP_STORAGE_URL + '/' + this.billingDetails[0].billing_invoice_no + '/' + item;
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
			this.seenAttachedFile.push(item);
		},
		approve(collection_number, current_status,amount) {
			this.error_message = '';

			let data = {};
			let newStatus = 3;
			switch(current_status){
			case 3:
				newStatus = 4;
				break;
			case 4:
				newStatus = 5;
				break;
			case 5:
				newStatus = 7;
				break;
						
			}
			data.accounting_status = newStatus;
			data.approved_by = this.currUser.id;
			data._method = 'PUT';
			if(amount > this.level1.max_amount && current_status === 3){
				data.accounting_status = newStatus;
			}else if(amount > this.level2.max_amount && current_status === 4){
				data.accounting_status = newStatus;
			} else {
				data.accounting_status = 7;
			}
			this.$store.dispatch('trxcollection/updateCollectionStatus', {id: collection_number, value: data});

		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		approveProps(current_user_name, totalAmount, amountThreshold, current_status, moveToStatus, options, data, filterByRegTrx, filterByRegTrxType) {
			var convertedFormData = '';
			let regularTrx = {};

			if (this.preparer == current_user_name) {
				this.error_message = 'Cannot be approved by the same Preparer and Approver';
				this.approveCollection = false;
				this.snackbar = true;
			} else {
				//If current status is 5, the status will automatically proceed as released
				if (totalAmount > amountThreshold && filterByRegTrx.length < 1 && current_status !== 5 && filterByRegTrxType.length < 1) {
					//Go to Approver 2 or 3
					data.value.status = moveToStatus;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxcollection/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				} else {
					//Go to Releasing
					this.documentDetails.filter(header => {
						this.dataRegularTrx.filter(trxdata => {			
							if (trxdata.doc_number === header.document_number) {
								regularTrx = {
									id: trxdata.id,
									doc_number: trxdata.doc_number,
									is_approved: 1
								};

								data.regular_trx_id = trxdata.id;
								this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
							}
						});
					});
					data.value.status = 7;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;

					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxcollection/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}	
			}
		},
		reject(collection_number) {
			let data = {};
			data.accounting_status = 20;
			data.collection_number = collection_number;
			data.approved_by = this.currUser.id;
			data._method = 'PUT';
			
			this.$store.dispatch('trxcollection/updateCollectionStatus', {id: collection_number, value: data});
		},
		closeDialog() {
			this.$store.dispatch('trxcollection/setViewDialog', false);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getValue(tableData, columnId, id, column){
			for (var i=0; i < tableData.length; i++) {
				if (tableData[i][columnId] === id) {
					if (columnId === 'acct_code'){
						return tableData[i][columnId] + ' - ' + tableData[i][column];
					} else {
						return tableData[i][column];
					}
				}
			}
		},
		getService(service_id,payee_id){
			let service_data = this.services.filter(det => det.payee_id === payee_id);
			return this.getValue(service_data, 'id', service_id,'collection_service_name');
		},
		getDocuType(type){
			switch(type){
			case 'CR':
				return 'Cash Receipt';
			}
		},
		getTransType(type){
			switch(type){
			case 1:
				return 'Regular';
			case 2:
				return 'Non-Regular';
			}
		},
		getLabel(status){
			if(status == 6){
				return 'Release';
			} else {
				return 'Approve';
			}
		},
		printCR() {
			let item = this.documentDetails[0];
			let routeData = this.$router.resolve({path: '/preview-trxcr/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		getRate(){
			let rate = 1;
			let dollar_rate = this.documentDetails[0].dollar_rate;
			let curr_code = 'P';
			let currentRate = [];
			let previousRate = [];
			let currency = [];
			let dateFormat = this.documentDetails[0].document_date;
			let currentMonth = getMonth(parseISO(dateFormat)) + 1;
			let currentYear = getYear(parseISO(dateFormat));

			if (dollar_rate !== '0.0000'){
				rate = dollar_rate;
			} else {
				currency = this.currencies.filter(det => {
					return det.id == this.documentDetails[0].currency_id;
				});

				if(currency.length > 0 ){
					curr_code = currency[0].cur_code;
				}

				currentRate = this.dataDollarRate.filter(det => {
					return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
				});

				if(currentRate.length > 0){
					rate = parseFloat(currentRate[0].dolr_rate);
				} else {
					previousRate = this.dataDollarRate.filter(det => {
						return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
					});

					if(previousRate.length > 0){
						rate = parseFloat(previousRate[0].dolr_rate);
					}
				}
			}

			return rate;
		},
		getCurrency(id){
			let curr_code = '';
			const currency = this.currencies.filter(det => det.id === id);

			if(currency.length > 0 ){
				curr_code = currency[0].cur_desc;
			}

			return curr_code;
		},
		async getDataDollarRate() {
			this.dataDollarRate = this.dollarrates.data;
			this.nextPageDollarRate= this.dollarrates.next_page_url;
			let page = 2;

			while (this.nextPageDollarRate !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageDollarRate = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDollarRate.push(details);
					});
				});
				page++;
			}
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts= this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		async getDataRegularTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		},
		getTotalAmount(data, column){
			let total_amount = data.details.reduce((sum, details) => {
				if (details[column] === '') {
					details[column] = 0;
				}

				return sum + parseFloat(details[column].replace(/,/g, ''));
			}, 0);
			return total_amount;
		}
	}
};
</script>
